.map-container {
  height: calc(100vh - 70px);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: -30px;
  margin-top: -70px;
  background-color: #f5f8fa;
}

.legend-btn-mobile {
  display: flex;
  position: absolute;
  top: 20px;
  left: 15px;
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
}

.legend-btn-mobile img {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 400px) {
  .map-container {
    height: calc(100vh - 60px);
    margin-top: -37px;
  }

  .esri-legend {
    width: 200px !important;
    padding: 0px !important;
  }

  .esri-legend__service {
    padding: 10px !important;
  }

  .legend-btn-mobile {
    top: 50px;
  }
}
